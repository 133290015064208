<template>
    <div id="document-by-client-component" class="min-height-component-550">
        <template v-if="!loading_my_files">
            <template v-if="my_files.length > 0">
                <p class="font-weight-bold mb-2">
                    {{ $t('document_by_client_component.total_documents', [my_files.length]) }}
                </p>
                <datatable-filter-component :headers="headers" class="mt-4" />

                <v-row class="justify-end ma-0 mb-4">
                    <div>
                        <v-btn class="square-btn-icon" color="secondary" outlined @click="downloadMyFile(null, $event)">
                            <v-icon color="secondary">mdi-download</v-icon>
                            <p v-if="selected_files.length > 0" class="secondary--text font-weight-bold mb-0 ml-2">
                                {{ selected_files.length }} {{ $t('document_by_client_component.selections') }}
                            </p>
                        </v-btn>
                    </div>
                </v-row>

                <v-dialog v-model="dialog_delete_file" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="text-uppercase font-weight-bold error--text">
                                {{ $t('document_by_client_component.delete_document_from_space') }}
                            </span>
                        </v-card-title>

                        <v-card-text class="pb-0">
                            <span>
                                {{ $t('document_by_client_component.delete_document') }} <span class="font-weight-bold">{{ file_to_delete.filename }}</span>
                                {{ $t('document_by_client_component.space') }}
                                <span class="font-weight-bold">{{ $store.getters['user_connected/user_connected'].client_space_name }}</span> ?
                            </span>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn text @click="dialog_delete_file = false">
                                <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                            </v-btn>
                            <v-btn :loading="loading_delete_file" class="btn-error-loader" color="error" text @click="deleteFile()">
                                <span class="error--text font-weight-bold">{{ $t('global.validate') }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- START PREVIEW DOCUMENT -->
                <v-dialog v-model="dialog_preview_pdf" max-width="700" scrollable>
                    <v-card>
                        <v-card-text class="pa-1">
                            <pdf :page="index_page_to_display" :src="selected_file.path_file" />
                            <v-progress-circular v-if="loading_my_file_pdf" class="pdf-loader" color="primary" indeterminate />
                        </v-card-text>

                        <v-divider />
                        <v-card-actions class="d-flex justify-space-between">
                            <v-icon @click="1 < index_page_to_display ? index_page_to_display-- : null"> mdi-chevron-left </v-icon>
                            <v-icon @click="total_pages > index_page_to_display ? index_page_to_display++ : null">
                                mdi-chevron-right
                            </v-icon>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_preview_picture" max-width="700">
                    <v-card min-height="20%">
                        <v-card-text class="pa-1">
                            <v-img :src="selected_file.path_file">
                                <template v-slot:placeholder>
                                    <v-row align="center" class="fill-height ma-0" justify="center">
                                        <v-progress-circular color="primary" indeterminate />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- END PREVIEW DOCUMENT -->

                <v-data-table
                    v-model="selected_files"
                    :headers="headers"
                    :items="my_files"
                    :search="search"
                    checkbox-color="primary"
                    class="stripe-date-table select-data-table"
                    disable-sort
                    item-key="id_file"
                    show-select
                >
                    <template v-slot:item.filename="{ item }">
                        <!-- FOR CONDAT TODO: DELETE -->
                        <span v-if="item.description.match('^https:\/\/[^\s/$.?#].[^\s]*$')">
                            <a :href="item.description" target="_blank">{{ item.filename }}</a>
                        </span>

                        <span v-else>{{ item.filename }}</span>
                    </template>

                    <template v-slot:item.date_creation="{ item }">
                        <span>{{ moment(item.date_creation).format(date_format) }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" @click="prepareOpenDialogPreview(item)">mdi-eye</v-icon>
                        <a class="text-decoration-none" @click="downloadMyFile(item, $event)">
                            <v-icon class="mr-2">mdi-download</v-icon>
                        </a>
                        <v-icon v-if="item.id_user" class="mr-2" @click="openDialogDeleteFile(item)">mdi-trash-can</v-icon>
                    </template>
                </v-data-table>
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('document_by_client_component.no_document_yet')"
                />
            </template>

            <v-dialog v-model="dialog_edit_file" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-uppercase font-weight-bold">
                            {{ $t('document_by_client_component.add_document_space') }}
                        </span>
                    </v-card-title>

                    <v-card-text class="pb-2 pt-2">
                        <v-file-input v-model="file" :label="$t('document_by_client_component.document')"
                                      accept="application/pdf,.png,.jpg,.jpeg" clearable filled prepend-icon=""
                                      prepend-inner-icon=""
                        />

                        <v-text-field v-model="edited_file.filename" :label="$t('document_by_client_component.document_name')"
                                      :rules="[rules.required]" filled
                        />

                        <v-textarea v-model="edited_file.description" :label="$t('document_by_client_component.description')"
                                    rows="2" filled hide-details
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialogEditFile();">
                            <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn :loading="loading_edit_file" class="btn-secondary-loader" color="secondary" text
                               @click="addFile()"
                        >
                                <span :class="valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                                    {{ $t('global.validate') }}
                                </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-btn v-if="userHasAccess('PWA_DOCUMENT', role_edit)" @click="dialog_edit_file = true;"
                   :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
                   color="secondary" elevation="1" fab
            >
                <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
        </template>

        <template v-else>
            <v-skeleton-loader type="table-thead, table-tbody" />
        </template>
    </div>
</template>

<script>
import DatatableFilterComponent from '@/components/Global/DatatableFilterComponent'
import pdf from 'vue-pdf'
import FileRepository from '@/repositories/FileRepository'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'

export default {
    name: 'DocumentByClientComponent',
    components: { EmptyPageComponent, DatatableFilterComponent, pdf },
    data() {
        return {
            module_designation: 'PWA_DOCUMENT',
            search: '',
            loading_my_files: false,
            my_files: [],
            headers: [
                { text: this.$t('document_by_client_component.document_name'), value: 'filename', filterable: true },
                { text: this.$t('document_by_client_component.description'), value: 'description', filterable: true },
                {
                    text: this.$t('document_by_client_component.date_publication'),
                    value: 'date_creation',
                    filterable: true,
                },
                { text: this.$t('document_by_client_component.origin'), value: 'origin', filterable: true },
                { text: this.$t('global.actions'), value: 'actions', filterable: true },
            ],
            rules: {
                required: (value) => !!value || this.$t('manage_equipment_component.required_field')
            },

            dialog_edit_file: false,
            loading_edit_file: false,
            file: null,
            edited_file: {
                filename: '',
                description: ''
            },

            dialog_preview_picture: false,
            dialog_preview_pdf: false,
            loading_my_file_pdf: false,
            total_pages: 0,
            index_page_to_display: 1,

            selected_files: [],
            selected_file: '',

            dialog_delete_file: false,
            loading_delete_file: false,
            file_to_delete: {}
        }
    },
    computed: {
        valid() {
            return this.edited_file.filename !== '' && this.file !== null;
        },
    },
    created() {
        this.getFilesBySpace()
    },
    methods: {
        getFilesBySpace() {
            this.loading_my_files = true

            FileRepository.getByIdProfessionalAndIdClientAndModuleDesignation(
                this.$store.getters['user_connected/user_connected'].id_professional,
                this.module_designation
            ).then((success) => {
                this.my_files = success.data.data;
            }).catch((error) => {
                this.manageError(error)
            }).finally(() => {
                this.loading_my_files = false;
            });
        },
        
        closeDialogEditFile() {
            this.dialog_edit_file = false;
            this.loading_edit_file = false;
            this.file = null;
            this.edited_file = {};
        },

        addFile() {
            this.loading_edit_file = true;

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('filename', this.edited_file.filename);
            formData.append('description', this.edited_file.description);

            FileRepository.postUploadFileForClientSpace(formData, this.module_designation).then(() => {
                this.getFilesBySpace();
                this.showSnackbar('success', this.$t('document_by_client_component.document_added'))
            }).catch((error) => {
                this.manageError(error)
            }).finally(() => {
                this.closeDialogEditFile();
            });
        },

        prepareOpenDialogPreview(file) {
            this.selected_file = file
            if (file.file_type.type === 'application/pdf') {
                this.openDialogPreviewPdf()
            } else {
                this.openDialogPreviewPicture()
            }
        },

        openDialogPreviewPicture() {
            this.dialog_preview_picture = true
        },

        openDialogPreviewPdf() {
            this.index_page_to_display = 1
            this.loading_my_file_pdf = true
            this.dialog_preview_pdf = true
            pdf.createLoadingTask(this.selected_file.path_file).promise.then((file) => {
                this.total_pages = file.numPages
                this.loading_my_file_pdf = false
            })
        },

        downloadMyFile(item, event) {
            event.preventDefault()

            let data = []
            if (this.selected_files.length > 0) {
                data = JSON.parse(JSON.stringify(this.selected_files))
            } else if (item) {
                data.push(item)
            } else {
                data = this.my_files
            }

            if (data.length > 0) {
                data.forEach((file) => {
                    this.downloadFile(file, 'PWA_DOCUMENT')
                })
            }
        },

        openDialogDeleteFile(file) {
            this.file_to_delete = file;
            this.dialog_delete_file = true;
        },

        deleteFile() {
            this.loading_delete_file = true;

            FileRepository.deleteFiles([this.file_to_delete.id_file], 'PWA_DOCUMENT').then(() => {
                this.showSnackbar('success', this.$t('document_by_client_component.document_deleted'));
                this.my_files.splice(this.my_files.indexOf(this.file_to_delete), 1);
            }).catch(error => {
                this.manageError(error);
            }).finally(() => {
                this.dialog_delete_file = false;
                this.loading_delete_file = false;
                this.file_to_delete = {};
            })
        }
    },
}
</script>

<style>
.pdf-loader {
    position: absolute !important;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}
</style>
