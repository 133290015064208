<template>
    <div id="document-page">
        <toolbar-component v-bind:title="$t('document_page.title')" />

        <v-tabs v-model="tab_selected" background-color="secondary" class="default-arrows-tabs" show-arrows slider-color="primary">
            <v-tab
                :class="(tab_selected === 'document-by-pro' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
                href="#document-by-pro"
            >
                {{ $t('document_page.documents_by_pro', [$store.getters['user_connected/user_connected'].professional_name]) }}
            </v-tab>

            <v-tab
                :class="(tab_selected === 'document-by-user' ? '' : 'tab-opacity') + ' primary--text font-weight-bold '"
                href="#document-by-user"
            >
                {{ $t('document_page.documents_by_user') }}
            </v-tab>

            <v-tab-item class="background pa-4" value="document-by-pro">
                <document-by-pro-component />
            </v-tab-item>

            <v-tab-item class="background pa-4" value="document-by-user">
                <document-by-client-component />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import ToolbarComponent from '@/components/Global/ToolbarComponent'
import DocumentByProComponent from '@/components/Document/DocumentByProComponent'
import DocumentByClientComponent from '@/components/Document/DocumentByClientComponent.vue'

export default {
    name: 'DocumentPage',
    components: { DocumentByClientComponent, DocumentByProComponent, ToolbarComponent },
    data() {
        return {
            tab_selected: '',
        }
    },
}
</script>
