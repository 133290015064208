<template>
    <div id="document-by-pro-component" class="min-height-component-550">
        <template v-if="!loading_pro_documents">
            <template v-if="pro_documents.length > 0">
                <div class="grid">
                    <v-row>
                        <v-col v-for="document in pro_documents" :key="document.id_file" cols="12" lg="4" md="6" xl="3">
                            <v-card elevation="0">
                                <v-card-text>
                                    <div class="gap-1 mb-2 d-flex justify-space-between align-center">
                                        <v-tooltip open-delay="500" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="text-overflow-ellipsis" v-bind="attrs" v-on="on">
                                                    {{ document.filename }}
                                                </span>
                                            </template>
                                            <span class="white--text">
                                                {{ document.filename }}
                                            </span>
                                        </v-tooltip>

                                        <menu-actions-component
                                            :actions="[
                                                {
                                                    title: $t('document_by_pro_component.download_document'),
                                                    icon: 'mdi-download',
                                                    emit: 'downloadUsefulDocuments',
                                                    has_access: true,
                                                },
                                                {
                                                    title: $t('document_by_pro_component.overview'),
                                                    icon: 'mdi-eye',
                                                    emit: 'prepareOpenDialogPreview',
                                                    has_access: true,
                                                },
                                            ]"
                                            v-on:downloadUsefulDocuments="downloadUsefulDocuments(document)"
                                            v-on:prepareOpenDialogPreview="prepareOpenDialogPreview(document)"
                                        />
                                    </div>

                                    <pdf :src="document.path_file" style="overflow: hidden; height: 300px; border-radius: 4px" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- START PREVIEW DOCUMENT -->
                <v-dialog v-model="dialog_preview_pdf" max-width="700" scrollable>
                    <v-card>
                        <v-card-text class="pa-1">
                            <pdf :page="index_page_to_display" :src="selected_file.path_file" />
                            <v-progress-circular v-if="loading_my_file_pdf" class="pdf-loader" color="primary" indeterminate />
                        </v-card-text>

                        <v-divider />
                        <v-card-actions class="d-flex justify-space-between">
                            <v-icon @click="1 < index_page_to_display ? index_page_to_display-- : null"> mdi-chevron-left </v-icon>
                            <v-icon @click="total_pages > index_page_to_display ? index_page_to_display++ : null">
                                mdi-chevron-right
                            </v-icon>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_preview_picture" max-width="700">
                    <v-card>
                        <v-card-text class="pa-1">
                            <v-img :src="selected_file.path_file">
                                <template v-slot:placeholder>
                                    <v-row align="center" class="fill-height ma-0" justify="center">
                                        <v-progress-circular color="primary" indeterminate />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- END PREVIEW DOCUMENT -->
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('document_by_pro_component.no_document_yet')"
                />
            </template>
        </template>

        <template v-else>
            <div class="grid">
                <v-row>
                    <v-col v-for="n in 4" :key="n" cols="12" lg="4" md="6" xl="3">
                        <v-skeleton-loader type="image" />
                    </v-col>
                </v-row>
            </div>
        </template>
    </div>
</template>

<script>
import FileRepository from '@/repositories/FileRepository'
import pdf from 'vue-pdf'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'

export default {
    name: 'DocumentByProComponent',
    components: { MenuActionsComponent, EmptyPageComponent, pdf },
    data() {
        return {
            search: '',
            pro_documents: [],
            loading_pro_documents: false,
            selected_pro_documents: [],
            headers: [
                { text: this.$t('document_by_pro_component.document_name'), value: 'filename', filterable: true },
                {
                    text: this.$t('document_by_pro_component.publication_date'),
                    value: 'date_creation',
                    filterable: true,
                },
                { text: this.$t('global.actions'), value: 'actions', filterable: true },
            ],

            selected_file: '',
            dialog_preview_picture: false,
            dialog_preview_pdf: false,
            loading_my_file_pdf: false,
            total_pages: 0,
            index_page_to_display: 1,
        }
    },
    created() {
        this.getUsefulDocuments()
    },
    methods: {
        getUsefulDocuments() {
            this.loading_pro_documents = true

            FileRepository.getByProfessionalId('PWA_DOCUMENT')
                .then((success) => {
                    this.pro_documents = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_pro_documents = false
                })
        },

        downloadUsefulDocuments(item) {
            let data = []
            if (this.selected_pro_documents.length > 0) {
                data = JSON.parse(JSON.stringify(this.selected_pro_documents))
            } else if (item) {
                data.push(item)
            } else {
                data = this.pro_documents
            }

            if (data.length > 0) {
                data.forEach((file) => {
                    this.downloadFile(file, 'PWA_DOCUMENT')
                })
            }
        },

        prepareOpenDialogPreview(file) {
            this.selected_file = file
            if (file.file_type.type === 'application/pdf') {
                this.openDialogPreviewPdf()
            } else {
                this.openDialogPreviewPicture()
            }
        },

        openDialogPreviewPicture() {
            this.dialog_preview_picture = true
        },

        openDialogPreviewPdf() {
            this.index_page_to_display = 1
            this.loading_my_file_pdf = true
            this.dialog_preview_pdf = true
            pdf.createLoadingTask(this.selected_file.path_file).promise.then((document) => {
                this.total_pages = document.numPages
                this.loading_my_file_pdf = false
            })
        },
    },
}
</script>
